import Button from "../../../Rebranding/atoms/Buttons/ButtonBase/Button.component";
import "./guarantee.scss";

export default function Guarantee(props) {
  const { ipa, handle, boundaries, translate } = props;

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const enableIPA = boundaries?.availableTraits?.DISABILITY;
  
  const contentButton = () => {
    if (!enableIPA && ipa === "basic") {
      return translate("checkpoint.buttons.ipaDisabled");
    }
    return ipa === "basic"
      ? translate("checkpoint.buttons.ipaBasic")
      : translate("checkpoint.buttons.ipaPremium");
  };
  return (
    <div className={"upsell-personalization-second-row-right"}>
      <div className={"upsell-personalization-custom-capital-ipa-container"}>
        <div className={"upsell-personalization-custom-capital-ipa-text"}>
          <p className={"upsell-personalization-custom-capital-ipa-title"}>
            {translate("checkpoint.guarantee.ipa")}
          </p>
        </div>
        <div className="buttons-container-guarantees">
          {(DOMAIN === "es" || DOMAIN === "pt") && (
            <Button
              id={"addButton1"}
              disabled={!enableIPA && ipa === "basic"}
              onClick={() => handle()}
              className={
                ipa === "basic"
                  ? "button-card-guarantee"
                  : "button-card-guarantee selected"
              }
            >
              {contentButton()}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
